import {createSlice} from '@reduxjs/toolkit';

const slice = createSlice({
    name: "app",
    initialState: {
        packages: [],
        payments: [],
        subscribed: false,
        subscription: [],
        courses: [],
        products: [],
        books: [],
        category: [],
        subscribed_courses: []
    },
    reducers: {
        //actions => action handlers
        SUBSCRIBED: (state, action) => {
            return {
                ...state,
                subscribed: action.payload.subscribed
            }
        },
        SUBSCRIBED_COURSES: (state, action) => {
            return {
                ...state,
                subscribed_courses: action.payload.subscribed_courses
            }
        },
        SUBSCRIPTION: (state, action) => {
            return {
                ...state,
                subscription: action.payload.subscription
            }
        },
        PACKAGES: (state, action) => {
            return {
                ...state,
                packages: action.payload.packages
            }
        },
        PAYMENTS: (state, action) => {
            return {
                ...state,
                payments: action.payload.payments
            }
        },
        COURSES: (state, action) => {
            return {
                ...state,
                courses: action.payload.courses
            }
        },
        BOOKS: (state, action) => {
            return {
                ...state,
                books: action.payload.books
            }
        },
        PRODUCTS: (state, action) => {
            return {
                ...state,
                products: action.payload.products
            }
        },
        CATEGORY: (state, action) => {
            return {
                ...state,
                category: action.payload.category
            }
        }
    }
});

export const {SUBSCRIBED, SUBSCRIBED_COURSES, PRODUCTS, PACKAGES, PAYMENTS, COURSES, BOOKS, CATEGORY} = slice.actions;
export default slice.reducer;
