import React, {useState} from 'react';
import $ from "jquery";
import {verifyTokenPayment, retryPayment} from "../../api/subscription";
import cogoToast from "cogo-toast";
import {EMPTY_CART} from "../../stores/cart";
import {useDispatch} from "react-redux";

const Provision = (props) => {

    const [stateReady, setStateReady] = useState(false);
    const [_stateReady, _setStateReady] = useState(false);
    const dispatch = useDispatch();

    const token = props.match.params.token;
    const phone = props.match.params.phone;

    const handleMomoPayment = (e) => {
        e.preventDefault();
        _setStateReady(true);

        retryPayment(phone, token).then((response) => {
            if(response.error) {
                _setStateReady(false);
                return false;
            }

            cogoToast.success(response.payload, {position: "top-right", hideAfter: 5});
            _setStateReady(false);
        });
    }

    const verifyPayment = (e) => {
        e.preventDefault();
        setStateReady(true);

        verifyTokenPayment(token).then((response) => {
            if(response.error) {
                setStateReady(false);
                return false;
            }

            dispatch(EMPTY_CART())
            setStateReady(false);
            props.history.push("/success");
        });
    }

    return (
        <div>
            <div className="container">
                <div className="flex flex-col items-center lg:py-10 text-center">
                    <ion-icon name="stopwatch-outline" style={{fontSize: 100}}></ion-icon>
                    <h3 className="mt-2 mb-2.5 font-semibold text-2xl lg:text-3xl">Please Wait. Processing... </h3>
                    <p>Your payment is being processed, Kindly be patient, you will receive confirmation in the next 2 minutes. If you have entered the PIN, press done.</p>
                    {stateReady ? <button className="hidden relative lg:flex items-center justify-start uk-width-1-6 py-3 mt-5 overflow-hidden text-lg font-medium text-white bg-gray-500 rounded-lg cursor-pointer"><span className="inline-block text-base text-center text-white w-full">Please Wait</span></button> : <button onClick={verifyPayment.bind(null)} className="hidden relative lg:flex items-center justify-start uk-width-1-6 py-3 mt-5 overflow-hidden text-lg font-medium text-white bg-green-700 rounded-lg cursor-pointer"><span className="inline-block text-base text-center text-white w-full">Done</span></button>}
                    {_stateReady ? <button className="hidden relative lg:flex items-center justify-start uk-width-1-6 py-3 mt-5 overflow-hidden text-lg font-medium text-white bg-gray-500 rounded-lg cursor-pointer"><span className="inline-block text-base text-center text-white w-full">Please Wait</span></button> : <button onClick={handleMomoPayment.bind(null)} className="hidden relative lg:flex items-center justify-start uk-width-1-6 py-3 mt-5 overflow-hidden text-lg font-medium text-white bg-red-700 rounded-lg cursor-pointer"><span className="inline-block text-base text-center text-white w-full">No Provision? Retry</span></button>}
                </div>
            </div>
        </div>
    );
};

export default Provision;
