import React, {useEffect, useState} from 'react';
import Footer from "../../components/layout/Footer";
import {decodeQueryParameter} from "../../utils/url";
import $ from "jquery";
import {chargeTokenCreditCard, chargeTokenMobileMoney} from "../../api/subscription";

const Payment = (props) => {

    const selected_package = decodeQueryParameter(props.match.params.package);
    const [stateReady, setStateReady] = useState(false);
    const [visaStateReady, setVisaStateReady] = useState(false);

    useEffect(() => {
        $('#card_payment').parsley();
        $('#momo_payment').parsley();

        return () => {
            $('#card_payment').parsley("destroy");
            $('#momo_payment').parsley("destroy");
        }
    }, []);

    const handleVisaPayment = (e) => {
        e.preventDefault();

        let cardNumber = $("#card_number").val();
        let cardExpiry = $("#expiry").val();
        let cardCvv = $("#cvv").val();
        let cardHolder = $("#card_holder").val();

        setVisaStateReady(true);

        chargeTokenCreditCard(selected_package._id, props.match.params.course_id, cardNumber, cardExpiry, cardCvv, cardHolder).then((response) => {
            if(response.error) {
                setVisaStateReady(false);
                return false;
            }

            setVisaStateReady(false);
            props.history.push("/success");
        });
    }

    const handleMomoPayment = (e) => {
        e.preventDefault();

        let phoneNumber = $("#mobile_number").val();

        setStateReady(true);

        chargeTokenMobileMoney(phoneNumber, selected_package._id, props.match.params.course_id).then((response) => {
            if(response.error) {
                setStateReady(false);
                return false;
            }

            setStateReady(false);
            props.history.push(`/provision/${response.payload.token_number}/${phoneNumber}`);
        });
    }

    return (
        <div>
            <div className="container">

                <div className="max-w-3xl mx-auto">

                    <h1 className="font-semibold mb-4 text-lg"> Choose payment method </h1>

                    <div className="bg-white rounded-md shadow-md">

                        <ul className=" space-y-0 rounded-md" uk-accordion="multiple: true">
                            <li className="uk-open">
                                <a className="uk-accordion-title border-b py-4 px-6" href="#">
                                    <div className="flex items-center text-base font-semibold">
                                        <ion-icon name="card-outline" class="mr-2"></ion-icon>
                                        Pay with Credit Card
                                    </div>
                                </a>
                                <div className="uk-accordion-content py-6 px-8 mt-0 border-b">

                                    <p className="">We accept following credit cards:&nbsp;&nbsp;
                                        <img className="inline-block" src="assets/images/cards.png" style={{width: 187}} alt="Cerdit Cards"/>
                                    </p>
                                    <form id="card_payment" className="grid sm:grid-cols-4 gap-4 mt-3" onSubmit={handleVisaPayment.bind()}>
                                        <div className="col-span-4">
                                            <input type="text" name="card_holder" id="card_holder" placeholder="Card Holder (Name appear on the card)" className="with-border" required/>
                                        </div>
                                        <div className="col-span-2">
                                            <input type="text" name="number" id="card_number" placeholder="Card Number" className="with-border" required/>
                                        </div>
                                        <div>
                                            <input type="text" name="expiry" id="expiry" placeholder="MM/YY" className="with-border" required/>
                                        </div>
                                        <div>
                                            <input type="text" name="cvv" id="cvv" placeholder="CVC" className="with-border" required/>
                                        </div>
                                        <div className="col-span-4 border rounded-md border-blue-500">
                                            {visaStateReady ? <button className="w-full py-3 font-semibold rounded text-blue-500 text-base" type="submit">Please Wait</button> : <button className="w-full py-3 font-semibold rounded text-blue-600 text-base" type="submit">Pay with VISA Card</button>}
                                        </div>
                                    </form>
                                </div>
                            </li>
                            <li>
                                <a className="uk-accordion-title border-b py-4 px-6" href="#">
                                    <div className="flex items-center text-base font-semibold">
                                        <ion-icon name="phone-portrait-outline" class="mr-2"></ion-icon>
                                        Pay with Mobile Money
                                    </div>
                                </a>
                                <div className="uk-accordion-content py-6 px-8 mt-0 border-b">

                                    <p className="">We accept following momo:&nbsp;&nbsp;
                                        <img className="inline-block" src="assets/images/momo.png" style={{width: 187}} alt="Cerdit Cards"/>
                                    </p>
                                    <form id="momo_payment" className="grid gap-4 mt-3" onSubmit={handleMomoPayment.bind()}>
                                        <div>
                                            <input type="number" id="mobile_number" name="mobile_number" placeholder="Mobile Number" className="with-border" required/>
                                        </div>

                                        <div className="flex flex-wrap items-center justify-between py-2 mt-3">
                                            {stateReady ? <button className="bg-gray-600 py-3 font-semibold p-2.5 rounded-md text-center text-white w-full" disabled>Please Wait</button> : <button className="bg-blue-600 py-3 font-semibold p-2.5 rounded-md text-center text-white w-full" type="submit">Pay with Mobile Money</button>}
                                        </div>
                                    </form>
                                </div>
                            </li>
                        </ul>

                    </div>

                </div>


            </div>
            <Footer/>
        </div>
    );
};

export default Payment;
