import React, {useState, useEffect} from 'react';
import {BeatLoader} from "react-spinners";
import {getAllCourse, getAllAcademic} from "../../api/app";
import Footer from "../../components/layout/Footer";
import {truncate} from "../../utils/trunate";
import {encodeQueryParameter} from "../../utils/url";
import {useSelector} from "react-redux";

const Academics = () => {

    const auth = useSelector((state) => state.auth);

    const [loading, setLoading] = useState(true);
    const [academics, setAcademics] = useState(null);

    useEffect(() => {
        getAllAcademic().then((response) => {
            if(!response.error) {
                setAcademics(response.payload)
                setLoading(false);
            }
        })
    }, []);

    return (
        <div>
            {loading ? <div className="h-screen flex item-center justify-center">
                    <div className="loader">
                        <BeatLoader color="#003074" size={20} />
                    </div>
                </div> :
                <div className="container">
                    <div className="md:flex justify-between items-center mb-8">
                        <div>
                            <div className="text-xl font-semibold">Courses</div>
                            <div className="text-sm mt-2 font-medium text-gray-500 leading-6"> Choose from {academics.length} courses
                                with new additions published every months
                            </div>
                        </div>
                    </div>

                    <div className="tube-card mt-3 lg:mx-0 -mx-5">

                        <h4 className="py-3 px-5 border-b font-semibold text-grey-700">
                            <ion-icon name="star"></ion-icon> Courses
                        </h4>

                        <div className="divide-y">
                            {academics.map((item) => {
                                return(
                                    <div className="flex md:space-x-6 space-x-3 md:p-5 p-2 relative">
                                        <a href={`#/academic/${item._id}`} className="md:w-60 md:h-36 w-28 h-20 overflow-hidden rounded-lg relative shadow-sm">
                                            <img src={item.cover.uri} alt="" className="w-full h-full absolute inset-0 object-cover"/>
                                            <img src="assets/images/icon-play.svg" className="w-12 h-12 uk-position-center" alt=""/>
                                        </a>
                                        <div className="flex-1 md:space-y-2 space-y-1">
                                            <a href={`#/academic/${item._id}`} className="md:text-xl font-semibold line-clamp-2"> {truncate(item.name, 30)} </a>
                                            <p className="leading-6 pr-4 line-clamp-2 md:block hidden"> {truncate(item.description, 130)} </p>
                                            <a href={`#/academic/${item._id}`} className="md:font-semibold block text-sm"> FREE</a>
                                            <div className="flex items-center justify-between">
                                                <div className="flex space-x-2 items-center text-sm">
                                                    <div> {item.category.name}</div>
                                                    <div className="md:block hidden">·</div>
                                                    <div className="flex items-center"> {item.contents.length} Lectures</div>
                                                </div>
                                                <a href={`#/academic/${item._id}`} className="md:flex items-center justify-center h-9 px-8 rounded-md border -mt-3.5 hidden"> View </a>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            }
            <Footer/>
        </div>
    );
};

export default Academics;
