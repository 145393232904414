import React from 'react';
import Footer from "../../components/layout/Footer";
import {decodeQueryParameter, encodeQueryParameter} from "../../utils/url";

const Category = (props) => {

    const packages = decodeQueryParameter(props.match.params.packages);

    return (
        <div>
            <div className="container">
                <div className="flex flex-col items-center lg:py-6 text-center">
                    <h3 className="mt-2 mb-2.5 font-semibold text-2xl lg:text-3xl"> Pricing Plans </h3>
                    <p> Our plans are simple, Start Learning To Day To Became Expert in this field.</p>
                </div>

                <div className="grid lg:grid-cols-3 lg:mt-20 mt-4 lg:gap-y-0 gap-y-5">
                    <div className="bg-white p-8 rounded-r-lg shadow space-y-2 mt-5">

                        <h4 className="font-semibold text-lg sm:text-2xl text-purple-900"> {packages[0].title} Plan</h4>
                        <p> To start your learning to day you will get this Course .</p>

                        <div className="bg-gray-100 p-3 rounded space-x-1.5">
                            <ul className="change-plan">
                                <li className="flex items-end justify-center">
                                    <div className="font-semibold text-3xl"> K{packages[0].amount}</div>
                                    <div className="font-medium text-lg uk-animation-slide-left-small"> / monthly</div>
                                </li>
                            </ul>
                        </div>

                        <ul className="py-3 space-y-1 text-gray-500">
                            <li> Access entire videos library</li>
                            <li> On-demand learning</li>
                            <li> Exercise files</li>
                            <li> High Quality Video Courses</li>
                        </ul>
                        <a href={`#/payment/${props.match.params.course_id}/${encodeQueryParameter(packages[0])}`} className="bg-blue-600 block border p-3 rounded-md space-x-1.5 text-center text-white text-sm">Get Started </a>
                    </div>

                    <div className="bg-white pt-16 lg:pt-8 p-8 shadow-xl space-y-2 relative -my-3 rounded-b-md">
                        <div className="lg:-top-10 top-0 absolute bg-blue-600 left-0 p-3 rounded-t-md text-center text-white w-full font-semibold"> Recommended</div>

                        <h4 className="font-semibold text-lg sm:text-2xl text-purple-900"> Pro Plan</h4>
                        <p> To start your learning to day you will get this Course .</p>

                        <div className="bg-gray-100 p-3 rounded space-x-1.5">
                            <ul className="change-plan">
                                <li className="flex items-end justify-center">
                                    <div className="font-semibold text-3xl"> K{packages[0].amount*3}</div>
                                    <div className="font-medium text-lg uk-animation-slide-left-small"> / 3 months</div>
                                </li>
                            </ul>
                        </div>

                        <ul className="py-3 space-y-1 text-gray-500">
                            <li> Access entire videos library</li>
                            <li> On-demand learning</li>
                            <li> Exercise files</li>
                            <li> High Quality Video Courses</li>
                        </ul>
                        <br/>
                        <a href="#" className="bg-blue-600 block p-3 rounded-md space-x-1.5 text-center text-sm text-white">Get Started </a>
                    </div>

                    <div className="bg-white p-8 rounded-r-lg shadow space-y-2 mt-5">

                        <h4 className="font-semibold text-lg sm:text-2xl text-purple-900"> Premium Plan</h4>
                        <p> To start your learning to day you will get this Course .</p>

                        <div className="bg-gray-100 p-3 rounded space-x-1.5">
                            <ul className="change-plan">
                                <li className="flex items-end justify-center">
                                    <div className="font-semibold text-3xl"> K{packages[0].amount*12}</div>
                                    <div className="font-medium text-lg uk-animation-slide-left-small"> / yearly</div>
                                </li>
                            </ul>
                        </div>

                        <ul className="py-3 space-y-1 text-gray-500">
                            <li> Access entire videos library</li>
                            <li> On-demand learning</li>
                            <li> Exercise files</li>
                            <li> High Quality Video Courses</li>
                        </ul>
                        <a href={`#/payment/${props.match.params.course_id}/${encodeQueryParameter(packages[0])}`} className="bg-blue-600 block border p-3 rounded-md space-x-1.5 text-center text-white text-sm">Get Started </a>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default Category;
