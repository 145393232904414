import React, {useEffect, useRef, useState} from 'react';
import { Link } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {logoutUser} from "../../api/auth";
import "simplebar";

const Header = () => {

    const auth = useSelector((state) => state.auth.user);

    const dispatch = useDispatch();

    const [menu, set_menu] = useState({
        explore: "",
        courses: "",
        book: "",
        category: "",
        exam: "",
        question: "",
        academia: ""
    });

    // //detect url change and update it.
    useEffect(() => {
        let url = window.location.hash.split("/").pop();
        Object.keys(menu).forEach(function(key){ set_menu((prevState) => ({
            ...prevState,
            [key]: "",
            [url]: "active"
        }))});
    }, [window.location.hash.split("/").pop()])

    const onLogoutClick = (event) => {
        event.preventDefault();
        dispatch(logoutUser());
    };

    return (
        <header uk-sticky="border-b">
            <div className="header_inner">
                <div className="left-side">

                    <div id="logo">
                        <Link to="/" className="flex font-semibold items-center lg:text-2xl text-xl text-black">
                            <span className="logo-name">ZStudy</span>
                        </Link>
                    </div>

                    <div className="triger" uk-toggle="target: .header_menu ; cls: is-visible"></div>

                    <nav className="header_menu">
                        <ul>
                            <li><a href="#/academics" className={menu.courses}> Academic </a></li>
                            <li><a href="#/grades" className={menu.category}> Grade </a></li>
                            <li><a href="#/books" className={menu.category}> Book </a></li>
                            <li><a href="#/exam-report" className={menu.exam}> Exam Report </a></li>
                            {auth.isAuthenticated ? <li><a href="#/question" className={menu.question}> Ask Question</a></li> : null}
                        </ul>
                    </nav>

                    <div className="overly" uk-toggle="target: .header_menu ; cls: is-visible"></div>
                </div>

                <div className="right-side">
                    {auth.isAuthenticated ?
                        <div>
                            <a href="#">
                                <img src="../assets/images/avatars/placeholder.png" className="header_widgets_avatar" alt=""/>
                            </a>
                            <div uk-drop="mode: click;offset:5" className="header_dropdown profile_dropdown">
                                <ul>
                                    <li>
                                        <a href="#" className="user">
                                            <div className="user_avatar">
                                                <img src="assets/images/avatars/placeholder.png" alt=""/>
                                            </div>
                                            <div className="user_name">
                                                <div> {auth.user.firstName ? `${auth.user.firstName} ${auth.user.lastName}` : "No Name"} </div>
                                                <span> {auth.user.email} </span>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <hr/>
                                    </li>
                                    <li>
                                        <a href="#/account">
                                            <ion-icon name="settings-outline" class="is-icon"></ion-icon>
                                            Account Settings
                                        </a>
                                    </li>
                                    <li>
                                        <hr/>
                                    </li>
                                    <li>
                                        <Link to="#" onClick={onLogoutClick.bind()}>
                                            <ion-icon name="log-out-outline" class="is-icon"></ion-icon>
                                            Log Out
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div> : <div>
                            <Link to="#/" className="px-6 px-4">Login</Link>
                        </div>}
                </div>
            </div>
        </header>
    );
};

export default Header;
