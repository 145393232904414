import React, {useState, useEffect} from 'react';
import {BeatLoader} from "react-spinners";
import {getUserSubscription} from "../../api/app";
import Footer from "../../components/layout/Footer";
import {truncate} from "../../utils/trunate";
import moment from "moment";

const Subscription = () => {
    const [loading, setLoading] = useState(true);
    const [subscriptions, setSubscriptions] = useState([]);

    useEffect(() => {
        getUserSubscription().then((response) => {
            if(!response.error) {
                setSubscriptions(response.subscriptions);
                setLoading(false);
            }
        });
    }, []);

    return (
        <div>
            {loading ? <div className="h-screen flex item-center justify-center">
                <div className="loader">
                    <BeatLoader color="#003074" size={20} />
                </div>
            </div> : <div>
                <div className="container">
                    <div className="max-w-3xl mx-auto">
                        <div className="bg-white rounded-md shadow-md">

                            <h3 className="border-b font-semibold px-5 py-4 text-base text-gray-500">{subscriptions.length !== 0 ? subscriptions.length : "No"} Subscription</h3>

                            {subscriptions.length !== 0 ? subscriptions.map((item) => {
                                    return(
                                        <div className="divide-y">
                                            <div className="flex items-start space-x-6 relative py-7 px-6">
                                                <div className="h-28 overflow-hidden relative rounded-md w-44">
                                                    <img src={item.course.cover} alt="" className="absolute w-full h-full inset-0 object-cover"/>
                                                </div>
                                                <div className="flex-1 min-w-0">
                                                    <a href={`#/course/${item.course._id}`} className="md:text-lg font-semibold line-clamp-2 mb-2">{item.course.name} </a>
                                                    <a href={`#/course/${item.course._id}`} className="font-medium block text-sm"> {truncate(item.course.description, 100)} </a>
                                                    <div className="flex items-center mt-5 space-x-2 text-sm font-medium">
                                                        <div> Expiry: {moment(item.due_date.iso).format("Do MMM, YYYY")}</div>
                                                        <div className="md:block hidden">·</div>
                                                        <div className="flex items-center"> Active</div>
                                                    </div>
                                                </div>
                                                <h5 className="font-semibold text-black text-xl"> K{item.transaction.amount} </h5>
                                            </div>
                                        </div>
                                    )
                                })
                                : null}
                        </div>
                    </div>
                </div>
            </div>}
            <Footer/>
        </div>
    );
};

export default Subscription;
