import React from 'react';
import Footer from "../../components/layout/Footer";
import {encodeQueryParameter} from "../../utils/url";
import {useSelector} from "react-redux";

const Success = (props) => {

    const auth = useSelector((state) => state.auth);

    const type = props.match.params.product;

    return (
        <div>
            <div className="container">
                <div className="flex flex-col items-center lg:py-10 text-center">
                    <div className="star-rating text-yellow-400">
                        <ion-icon name="star" style={{fontSize: 30}}></ion-icon>
                        <ion-icon name="star" style={{fontSize: 30}}></ion-icon>
                        <ion-icon name="star" style={{fontSize: 30}}></ion-icon>
                        <ion-icon name="star" style={{fontSize: 30}}></ion-icon>
                        <ion-icon name="star-half" style={{fontSize: 30}}></ion-icon>
                    </div>
                    <h3 className="mt-2 mb-2.5 font-semibold text-2xl lg:text-3xl"> Payment Successful </h3>
                    <p> Thank you for your payment. Go to order menu to see your orders.</p>

                    <a href="#/" className="hidden relative lg:flex items-center justify-start uk-width-1-6 py-3 mt-5 overflow-hidden text-lg font-medium text-white bg-green-700 rounded-lg cursor-pointer">
                        <span className="inline-block text-base text-center text-white w-full">Take me to home</span>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Success;
