import React from 'react';

const PageError = () => {

    document.title = "Page Not Found";

    return (
        <h1>
            <p>Error Pages</p>
        </h1>
    );
};

export default PageError;
