import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import {useSelector} from 'react-redux';
import Header from "../layout/Header";

const PrivateRoute = ({component: Component, ...rest}) => {
    const auth = useSelector((state) => state.auth);
    return(
        <Route
            {...rest}
            render={props =>
                auth.isAuthenticated === true ? (
                    <div id="wrapper" className="horizontal">
                        <Header/>
                        <Component {...props} />
                    </div>
                ) : (
                    <Redirect to="/"/>
                )
            }
        />
    )
};

export default PrivateRoute;
