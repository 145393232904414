import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import formatNumber from "../../utils/formatNumber";
import {ADD_QUANTITY, REMOVE_FROM_CART, SUB_QUANTITY} from "../../stores/cart";
import {encodeQueryParameter} from "../../utils/url";
import product from "../public/Product";

const Cart = () => {

    const carts = useSelector((state) => state.cart.products);

    const dispatch = useDispatch();

    const onPlusCart = (item) => {
        dispatch(ADD_QUANTITY({_id: item._id}));
    }

    const onMinusCart = (item) => {
        dispatch(SUB_QUANTITY({_id: item._id}));
    }

    const removeItemCart = (id) => {
        dispatch(REMOVE_FROM_CART({_id: id}))
    }

    const subtotal = () => {
        return carts.reduce(function (result, item) {
            return result + item.ori_price * item.quantity;
        }, 0);
    }

    return (
        <div className="container">
            <div className="max-w-3xl mx-auto lg:-mt-50 relative">

                <div className="bg-white rounded-md shadow-md">

                    <h3 className="border-b font-semibold px-5 py-4 text-base text-gray-500"> Your cart ({carts.length} items)</h3>

                    <div className="divide-y">
                        {carts.length > 0 ? carts.map((item) => {
                                return(
                                    <div className="flex items-start space-x-6 relative py-7 px-6">
                                        <div className="h-28 overflow-hidden relative rounded-md w-44">
                                            <img src={item.img} alt="" className="absolute w-full h-full inset-0 object-contain"/>
                                        </div>
                                        <div className="flex-1 min-w-0">
                                            <a href="" className="md:text-lg font-semibold line-clamp-2 mb-2">{item.name}</a>
                                            <span className="font-medium block text-sm"> K{formatNumber(item.ori_price)} x {item.quantity} </span>
                                            <div className="flex items-center mt-7 space-x-2 text-sm font-medium">
                                                <ion-icon onClick={onMinusCart.bind(null, item)} name="remove-circle-outline" class="is-icon" style={{fontSize: 30}}></ion-icon>
                                                <div className="md:block hidden" style={{fontSize: 18}}>{item.quantity}</div>
                                                <ion-icon onClick={onPlusCart.bind(null, item)} name="add-circle-outline" className="is-icon" style={{fontSize: 30}}></ion-icon>
                                            </div>
                                        </div>
                                        <h5 className="font-semibold text-black text-xl"> K{item.ori_price * item.quantity}</h5>
                                        <button className="absolute bottom-9 font-semibold right-4 text-blue text-blue-500" onClick={removeItemCart.bind(null, item._id)}> Remove </button>
                                    </div>
                                )
                        }) : <p className="text-center pt-4">The cart is empty. You do not have any cart yet.</p>}
                    </div>

                    <div className="border-t mt-5 pt-6 space-y-6">

                        <div className="flex justify-between px-6">
                            <div className="flex-1 min-w-0">
                                <h1 className="text-lg font-medium"> Subtotal </h1>
                                <p> Delivery is <strong>FREE</strong> country wide </p>
                            </div>
                            <h5 className="font-semibold text-black text-xl"> K{subtotal()} </h5>
                        </div>

                        <div className="px-6 pb-5">
                            {carts.length <= 0 ? <a href="#/products" className="bg-blue-600 block py-3 rounded-md text-white text-center text-base font-semibold hover:text-white hover:bg-blue-700"> Browse Products </a> : <a href={`#/purchase/${encodeQueryParameter(Object.assign({product: product, type: "product"}))}`} className="bg-blue-600 block py-3 rounded-md text-white text-center text-base font-semibold hover:text-white hover:bg-blue-700"> Checkout </a>}
                            <div className="flex items-center justify-center mt-4 space-x-1.5">
                                {carts.length > 0 ? <><p className="font-medium"> or </p> <a href="#/products" className="text-blue-600 font-semibold text-center"> Continue Shopping</a></> : null}
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    );
};

export default Cart;
