import React, {useEffect, useState} from 'react';
import Footer from "../../components/layout/Footer";
import $ from "jquery";
import {
    bookChargeTokenMobileMoney,
    productChargeTokenCreditCard,
    productChargeTokenMobileMoney
} from "../../api/subscription";
import {decodeQueryParameter} from "../../utils/url";
import {useDispatch, useSelector} from "react-redux";
import {EMPTY_CART} from "../../stores/cart";

const Purchase = (props) => {

    const [stateReady, setStateReady] = useState(false);
    const [visaStateReady, setVisaStateReady] = useState(false);
    const dispatch = useDispatch();

    const carts = useSelector((state) => state.cart.products);

    const product = decodeQueryParameter(props.match.params.product);

    useEffect(() => {
        $('#card_payment').parsley();
        $('#momo_payment').parsley();

        return () => {
            $('#card_payment').parsley("destroy");
            $('#momo_payment').parsley("destroy");
        }
    }, []);

    const handleVisaPayment = (e) => {
        e.preventDefault();

        let cardNumber = $("#card_number").val();
        let cardExpiry = $("#expiry").val();
        let cardCvv = $("#cvv").val();
        let cardHolder = $("#card_holder").val();

        let full_name = $("#full_name").val();
        let contact_number = $("#contact_number").val();
        let delivery_address = $("#delivery_address").val();

        if(!full_name) return alert("Please enter your full name");
        if(!contact_number) return alert("Please enter your contact number");
        if(!delivery_address) return alert("Please enter your delivery address");

        setVisaStateReady(true);

        let products = [];

        carts.map((item) => {
            products.push({
                id: item._id,
                count: item.quantity
            })
        });

        let data = {
            products,
            "card_number": cardNumber,
            "card_expiry": cardExpiry,
            "card_cvv": cardCvv,
            "card_holder": cardHolder,
            "delivery_address": {
                "name": full_name,
                "contact": contact_number,
                "address": delivery_address
            }
        }

        setTimeout(() => {
            setVisaStateReady(false);
            button.prop("disabled", false);
        }, 3000);

        let button = $("button");
        button.prop("disabled", true);

        productChargeTokenCreditCard(data).then((response) => {
            if(response.error) {
                setVisaStateReady(false);
                button.prop("disabled", false);
                return false;
            }

            dispatch(EMPTY_CART());
            setVisaStateReady(false);
            button.prop("disabled", false);
            props.history.push(`/success`);
        });
    }

    const handleMomoPayment = (e) => {
        e.preventDefault();

        let phoneNumber = $("#mobile_number").val();

        let full_name = $("#full_name").val();
        let contact_number = $("#contact_number").val();
        let delivery_address = $("#delivery_address").val();

        if(!full_name) return alert("Please enter your full name");
        if(!contact_number) return alert("Please enter your contact number");
        if(!delivery_address) return alert("Please enter your delivery address");

        setStateReady(true);

        if(product.type == "product") {

            let products = [];

            carts.map((item) => {
                products.push({
                    id: item._id,
                    count: item.quantity
                })
            });

            let data = {
                products,
                "phone": phoneNumber,
                "delivery_address": {
                    "name": full_name,
                    "contact": contact_number,
                    "address": delivery_address
                }
            }

            productChargeTokenMobileMoney(data).then((response) => {
                if(response.error) {
                    setStateReady(false);
                    return false;
                }

                setStateReady(false);
                props.history.push(`/provision/${response.payload.token_number}/${phoneNumber}`);
            });
        } else {
            bookChargeTokenMobileMoney(phoneNumber, product._id, product.type, full_name, contact_number, delivery_address).then((response) => {
                if(response.error) {
                    setStateReady(false);
                    return false;
                }

                setStateReady(false);
                props.history.push(`/provision/${response.payload.token_number}/${phoneNumber}`);
            });
        }
    }

    return (
        <div>
            <div className="container">

                <div className="max-w-3xl mx-auto">

                    <h1 className="font-semibold mb-4 text-lg"> Personal Detail </h1>
                    <div className="bg-white rounded-md lg:shadow-md shadow col-span-2">
                        <form id="form_profile" noValidate="">
                            <div className="lg:p-6 p-4 space-y-4">
                                <div><label htmlFor="full_name"> Full Name</label><input type="text" placeholder="Enter your full name" id="full_name" className="shadow-none with-border" required=""/></div>
                                <div><label htmlFor="contact_number"> Contact Mobile Number</label><input type="text" placeholder="Enter your contact mobile number" id="contact_number" className="shadow-none with-border" required=""/></div>
                                <div className="col-span-2"><label htmlFor="delivery_address"> Delivery Address</label><input type="text" placeholder="Enter your delivery address" id="delivery_address" className="shadow-none with-border" required=""/></div>
                            </div>
                        </form>
                    </div>

                    <h1 className="font-semibold mb-4 text-lg mt-5"> Choose payment method </h1>

                    <div className="bg-white rounded-md shadow-md">

                        <ul className=" space-y-0 rounded-md" uk-accordion="multiple: true">
                            <li>
                                <a className="uk-accordion-title border-b py-4 px-6" href="#">
                                    <div className="flex items-center text-base font-semibold">
                                        <ion-icon name="phone-portrait-outline" className="mr-2"></ion-icon>
                                        Pay with Mobile Money
                                    </div>
                                </a>
                                <div className="uk-accordion-content py-6 px-8 mt-0 border-b">

                                    <p className="">We accept following momo:&nbsp;&nbsp;
                                        <img className="inline-block" src="assets/images/momo.png" style={{width: 187}}
                                             alt="Cerdit Cards"/>
                                    </p>
                                    <form id="momo_payment" className="grid gap-4 mt-3"
                                          onSubmit={handleMomoPayment.bind()}>
                                        <div>
                                            <input type="number" id="mobile_number" name="mobile_number" placeholder="Mobile Number" className="with-border" required/>
                                        </div>

                                        <div className="flex flex-wrap items-center justify-between py-2 mt-3">
                                            {stateReady ? <button
                                                className="bg-gray-600 py-3 font-semibold p-2.5 rounded-md text-center text-white w-full"
                                                disabled>Please Wait</button> : <button
                                                className="bg-blue-600 py-3 font-semibold p-2.5 rounded-md text-center text-white w-full"
                                                type="submit">Pay with Mobile Money</button>}
                                        </div>
                                    </form>
                                </div>
                            </li>
                            <li>
                                <a className="uk-accordion-title border-b py-4 px-6" href="#">
                                    <div className="flex items-center text-base font-semibold">
                                        <ion-icon name="card-outline" class="mr-2"></ion-icon>
                                        Pay with Credit Card
                                    </div>
                                </a>
                                <div className="uk-accordion-content py-6 px-8 mt-0 border-b">

                                    <p className="">We accept following credit cards:&nbsp;&nbsp;
                                        <img className="inline-block" src="assets/images/cards.png" style={{width: 187}} alt="Cerdit Cards"/>
                                    </p>
                                    <form id="card_payment" className="grid sm:grid-cols-4 gap-4 mt-3" onSubmit={handleVisaPayment.bind()}>
                                        <div className="col-span-4">
                                            <input type="text" name="number" id="card_holder" placeholder="Card Holder (Name appear on the card)" className="with-border" required/>
                                        </div>
                                        <div className="col-span-2">
                                            <input type="number" name="number" id="card_number" placeholder="Card Number" className="with-border" required/>
                                        </div>
                                        <div>
                                            <input type="number" name="expiry" id="expiry" placeholder="MM/YY" className="with-border" required/>
                                        </div>
                                        <div>
                                            <input type="number" name="cvv" id="cvv" placeholder="CVV" className="with-border" required/>
                                        </div>
                                        <div className="col-span-4 border rounded-md border-blue-500">
                                            {visaStateReady ? <button className="w-full py-3 font-semibold rounded text-blue-500 text-base" type="submit">Please Wait</button> : <button className="w-full py-3 font-semibold rounded text-blue-600 text-base" type="submit">Pay with VISA Card</button>}
                                        </div>
                                    </form>
                                </div>
                            </li>
                        </ul>

                    </div>

                </div>


            </div>
            <Footer/>
        </div>
    );
};

export default Purchase;
