import {createSlice} from '@reduxjs/toolkit';

const slice = createSlice({
    name: "cart",
    initialState: {
        products: []
    },
    reducers: {
        //actions => action handlers
        ADD_TO_CART: (state, action) => {
            const item = action.payload.products[0];
            const root = state.products.find((data) => {
                return data._id === item._id
            });

            return {
                ...state,
                products: root !== undefined ? state.products : [...state.products, item]
            }
        },
        REMOVE_FROM_CART: (state, action) => {
            return {
                ...state,
                products: state.products.filter((data) => data._id !== action.payload._id)
            };
        },
        ADD_QUANTITY: (state, action) => {
            return {
                ...state,
                products: state.products.map(product =>
                    product._id === action.payload._id
                        ? {...product, quantity: product.quantity + 1}
                        : product,
                ),
            };
        },
        SUB_QUANTITY: (state, action) => {
            return {
                ...state,
                products: state.products.map(product =>
                    product._id === action.payload._id
                        ? {
                            ...product,
                            quantity: product.quantity !== 1 ? product.quantity - 1 : 1,
                        }
                        : product,
                ),
            };
        },
        EMPTY_CART: () => {
            return {
                products: []
            };
        }
    }
});

export const {  ADD_TO_CART, REMOVE_FROM_CART, ADD_QUANTITY, SUB_QUANTITY, EMPTY_CART} = slice.actions;
export default slice.reducer;
