import React, {useEffect, useState} from 'react';
import Footer from "../../components/layout/Footer";
import $ from "jquery";
import {updateAccount, updatePassword} from "../../api/app";
import cogoToast from "cogo-toast";
const Account = () => {

    const [passwordLoading, setPasswordLoading] = useState(false);

    useEffect(() => {
        $('#form_profile').parsley();
        $('#form_password').parsley();

        return () => {
            $('#form_profile').parsley("destroy");
            $('#form_password').parsley("destroy");
        }
    }, []);

    const onUpdatePassword = (e) => {
        e.preventDefault();
        setPasswordLoading(true);

        let currentPassword = $("#current_password").val();
        let newPassword = $("#new_password").val();

        updatePassword(currentPassword, newPassword).then((response) => {
            setPasswordLoading(false);
            if(!response.error) {
                cogoToast.success("Password successfully updated.", {position: "top-center", hideAfter: 5})
                $("#current_password").val(null)
                $("#new_password").val(null);
                $("#confirm_new_password").val(null);
            }
        });
    }

    const onUpdateAccount = (e) => {
        e.preventDefault();
        setPasswordLoading(true);

        let first_name = $("#first_name").val();
        let last_name = $("#last_name").val();
        let email = $("#email").val();

        let raw = {
            first_name,
            last_name,
            email
        };

        updateAccount(raw).then((response) => {
            setPasswordLoading(false);
            if(!response.error) {
                cogoToast.success("Account successfully updated.", {position: "top-center", hideAfter: 5})
                $("#current_password").val(null)
                $("#new_password").val(null);
                $("#confirm_new_password").val(null);
            }
        });
    }

    return (
        <div>
            <div className="container">
                <h3 className="text-2xl font-medium mb-5"> General Setting </h3>

                {/*<div className="grid lg:grid-cols-3 gap-8 md:mt-12">*/}
                {/*    <div>*/}
                {/*        <div uk-sticky="offset:100;bottom:true;media:992">*/}
                {/*            <h3 className="text-lg mb-2 font-semibold"> Profile</h3>*/}
                {/*            <p> Account preferences such as updating email, first name and last name.</p>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className="bg-white rounded-md lg:shadow-md shadow col-span-2">*/}
                {/*        <form id="form_profile" onSubmit={onUpdateAccount.bind()}>*/}
                {/*            <div className="lg:p-6 p-4 space-y-4">*/}
                {/*                <div>*/}
                {/*                    <label htmlFor="first-name"> First name</label>*/}
                {/*                    <input type="text" placeholder="" id="first_name" className="shadow-none with-border" required/>*/}
                {/*                </div>*/}
                {/*                <div>*/}
                {/*                    <label htmlFor="last-name"> Last name</label>*/}
                {/*                    <input type="text" placeholder="" id="last_name" className="shadow-none with-border" required/>*/}
                {/*                </div>*/}
                {/*                <div className="col-span-2">*/}
                {/*                    <label htmlFor="email"> Email</label>*/}
                {/*                    <input type="text" placeholder="" id="email" className="shadow-none with-border" required/>*/}
                {/*                </div>*/}
                {/*                <button className="bg-blue-600 py-3 font-semibold p-2.5 rounded-md text-center text-white w-full" type="submit">Update Profile</button>*/}
                {/*            </div>*/}
                {/*        </form>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div className="grid lg:grid-cols-3 gap-8 md:mt-20">
                    <div>
                        <div uk-sticky="offset:100;bottom:true;media:992">
                            <h3 className="text-lg mb-2 font-semibold"> Password</h3>
                            <p>Settings for creating a sophisticated password for strong security</p>
                        </div>
                    </div>
                    <div className="bg-white rounded-md lg:shadow-md shadow col-span-2">
                        <form id="form_password" onSubmit={onUpdatePassword.bind()}>
                            <div className="lg:p-6 p-4 space-y-4">
                                <div>
                                    <label htmlFor="current_password"> Current password <span className="text-red-600">*</span></label>
                                    <input type="text" placeholder="" id="current_password" className="shadow-none with-border" required/>
                                </div>
                                <div>
                                    <label htmlFor="new_password"> New password <span className="text-red-600">*</span></label>
                                    <input type="text" placeholder="" id="new_password" className="shadow-none with-border" required/>
                                </div>
                                <div>
                                    <label htmlFor="confirm_new_password"> Confirm new password <span className="text-red-600">*</span></label>
                                    <input type="text" data-parsley-equalto="#new_password" placeholder="" id="confirm_new_password" className="shadow-none with-border" required/>
                                </div>
                                {passwordLoading ? <button className="bg-gray-600 py-3 font-semibold p-2.5 rounded-md text-center text-white w-full" disabled>Please Wait</button> : <button className="bg-blue-600 py-3 font-semibold p-2.5 rounded-md text-center text-white w-full" type="submit">Change Password</button>}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default Account;
