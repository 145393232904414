import React, {useState, useEffect} from 'react';
import {BeatLoader} from "react-spinners";
import {getUserOrder} from "../../api/app";
import Footer from "../../components/layout/Footer";
import {truncate} from "../../utils/trunate";
import moment from "moment";

const Order = () => {
    const [loading, setLoading] = useState(true);
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        getUserOrder().then((response) => {
            if(!response.error) {
                setOrders(response.orders);
                setLoading(false);
            }
        });
    }, []);

    return (
        <div>
            {loading ? <div className="h-screen flex item-center justify-center">
                <div className="loader">
                    <BeatLoader color="#003074" size={20} />
                </div>
            </div> : <div>
                <div className="container">
                    <div className="max-w-3xl mx-auto">
                        <div className="bg-white rounded-md shadow-md">

                            <h3 className="border-b font-semibold px-5 py-4 text-base text-gray-500">{orders.length !== 0 ? orders.length : "No"} Order</h3>

                            {orders.length !== 0 ? orders.map((item) => {
                                    return(
                                        <div className="divide-y">
                                            <div className="flex items-start space-x-6 relative py-7 px-6">
                                                <div className="h-28 overflow-hidden relative rounded-md w-44">
                                                    <img src={item.course.cover} alt="" className="absolute w-full h-full inset-0 object-cover"/>
                                                </div>
                                                <div className="flex-1 min-w-0">
                                                    <a href={`#/product/${item.product._id}`} className="md:text-lg font-semibold line-clamp-2 mb-2">{item.product.name} </a>
                                                    <a href={`#/product/${item.product._id}`} className="font-medium block text-sm"> {truncate(item.product.description, 100)} </a>
                                                    {/*<div className="flex items-center mt-5 space-x-2 text-sm font-medium">*/}
                                                    {/*    <div> Expiry: {moment(item.due_date.iso).format("Do MMM, YYYY")}</div>*/}
                                                    {/*    <div className="md:block hidden">·</div>*/}
                                                    {/*    <div className="flex items-center"> Active</div>*/}
                                                    {/*</div>*/}
                                                </div>
                                                <h5 className="font-semibold text-black text-xl"> K{item.transaction.amount} </h5>
                                            </div>
                                        </div>
                                    )
                                })
                                : null}
                        </div>
                    </div>
                </div>
            </div>}
            <Footer/>
        </div>
    );
};

export default Order;
