import axios from "axios";
import {
    CHARGE_DEBIT_CARD_TOKEN_URL,
    CHARGE_MOBILE_MONEY_TOKEN_URL,
    PRODUCT_CHARGE_DEBIT_CARD_TOKEN_URL,
    PRODUCT_CHARGE_MOBILE_MONEY_TOKEN_URL,
    VERIFY_TOKEN_PAYMENT_URL,
    RETRY_PAYMENT_URL,
    BOOK_CHARGE_MOBILE_MONEY_TOKEN_URL
} from "./index";
import errorHandler from "../utils/errorHandler"
export const chargeTokenCreditCard = (id, course_id, card_number, card_expiry, card_cvv, card_holder) => {
    const token = localStorage.getItem('jwtToken');
    return axios.post(CHARGE_DEBIT_CARD_TOKEN_URL, {id, course_id, card_number: card_number.trim(), card_expiry: card_expiry.trim(), card_cvv, card_holder}, {headers: {"Authorization": token}}).then((response) => {
        return {
            error: false,
            payload: response.data.payload
        }
    }).catch((error) => {
        return errorHandler(error);
    });
};
export const chargeTokenMobileMoney = (phone, id, course_id) => {
    const token = localStorage.getItem('jwtToken');
    return axios.post(CHARGE_MOBILE_MONEY_TOKEN_URL, {id, phone, course_id}, {headers: {"Authorization": token}}).then((response) => {
        return {
            error: false,
            payload: response.data.payload
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: null,
            error: true
        }
    });
};

export const retryPayment = (phone, token_number) => {
    const token = localStorage.getItem('jwtToken');
    return axios.post(RETRY_PAYMENT_URL, {phone, token: token_number}, {headers: {"Authorization": token}}).then((response) => {
        return {
            error: false,
            payload: response.data.payload
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: null,
            error: true
        }
    });
}

export const verifyTokenPayment = (token_number) => {
    const token = localStorage.getItem('jwtToken');
    return axios.post(VERIFY_TOKEN_PAYMENT_URL, {token: token_number}, {headers: {"Authorization": token}}).then((response) => {
        return {
            error: false,
            payload: response.data.payload
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: null,
            error: true
        }
    });
}

export const productChargeTokenCreditCard = (data) => {
    const token = localStorage.getItem('jwtToken');
    return axios.post(PRODUCT_CHARGE_DEBIT_CARD_TOKEN_URL, data, {headers: {"Authorization": token}}).then((response) => {
        return {
            error: false,
            payload: response.data.payload
        }
    }).catch((error) => {
        return errorHandler(error);
    });
};

export const productChargeTokenMobileMoney = (data) => {
    const token = localStorage.getItem('jwtToken');
    return axios.post(PRODUCT_CHARGE_MOBILE_MONEY_TOKEN_URL, data, {headers: {"Authorization": token}}).then((response) => {
        return {
            error: false,
            payload: response.data.payload
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: null,
            error: true
        }
    });
};

export const bookChargeTokenMobileMoney = (phone, id, type, name, contact, address) => {
    const token = localStorage.getItem('jwtToken');
    return axios.post(BOOK_CHARGE_MOBILE_MONEY_TOKEN_URL, {id, phone, type, name, contact, address}, {headers: {"Authorization": token}}).then((response) => {
        return {
            error: false,
            payload: response.data.payload
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: null,
            error: true
        }
    });
};
