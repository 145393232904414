import React from 'react';
import PrivateRoute from '../../components/common/PrivateRoute';
import {Switch, Route} from 'react-router-dom';
import PageError from "../../components/layout/PageError";
import Question from "../../pages/private/Question";
import Category from "../../pages/public/Category";
import Pricing from "../../pages/private/Pricing";
import Payment from "../../pages/private/Payment";
import Subscription from "../../pages/private/Subscription";
import Account from "../../pages/private/Account";
import Success from "../../pages/private/Success";
import Provision from "../../pages/private/Provision";
import Purchase from "../../pages/private/Purchase";
import Order from "../../pages/private/Order";
import Cart from "../../pages/private/Cart";
import Interactive from "../../pages/private/Interactive";
import Simulator from "../../pages/private/Simulator";
import Academics from "../../pages/private/Academics";
import Academic from "../../pages/private/Academic";
import Book from "../../pages/private/Book";
import Books from "../../pages/private/Books";
import Grade from "../../pages/private/Grade";
import AcademicList from "../../pages/private/AcademicList";
import ExamReport from "../../pages/private/ExamReport";

const Private = () => {
        return (
            <Switch>
                    <PrivateRoute exact path="/academics" component={Academics} />
                    <PrivateRoute exact path="/academic/:id" component={Academic} />
                    <PrivateRoute exact path="/books" component={Books} />
                    <PrivateRoute exact path="/book/:id" component={Book} />
                    <PrivateRoute exact path="/exam-report" component={ExamReport} />
                    <PrivateRoute exact path="/grades" component={Grade} />
                    <PrivateRoute exact path="/grade-list/:id" component={AcademicList} />

                    <PrivateRoute exact path="/question" component={Question} />
                    <PrivateRoute exact path="/subscription" component={Subscription} />
                    <PrivateRoute exact path="/interactive/:simulator" component={Interactive} />
                    <PrivateRoute exact path="/simulator" component={Simulator} />
                    <PrivateRoute exact path="/order" component={Order} />
                    <PrivateRoute exact path="/category" component={Category} />
                    <PrivateRoute exact path="/account" component={Account} />
                    <PrivateRoute exact path="/cart" component={Cart} />
                    <PrivateRoute exact path="/success" component={Success} />
                    <PrivateRoute exact path="/provision/:token/:phone" component={Provision} />
                    <PrivateRoute exact path="/pricing/:course_id/:packages" component={Pricing} />
                    <PrivateRoute exact path="/payment/:course_id/:package" component={Payment} />
                    <PrivateRoute exact path="/purchase/:product" component={Purchase} />
                    <Route component={PageError} />
            </Switch>
        )
};

export default Private;
