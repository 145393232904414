import { combineReducers } from 'redux';
import authReducer from './auth';
import appReducer from './app';
import cartReducer from './cart';

export default combineReducers(Object.assign(
    {auth: authReducer},
    {app: appReducer},
    {cart: cartReducer}
));
