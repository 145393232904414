import React, {useState, useEffect, useRef} from 'react';
import {BeatLoader} from "react-spinners";
import {getCourse, getAcademic} from "../../api/app";
import Footer from "../../components/layout/Footer";
import {truncate} from "../../utils/trunate";
import {Link} from "react-router-dom";
import $ from "jquery";
import {encodeQueryParameter} from "../../utils/url";
import {useSelector} from "react-redux";
import UIkit from "uikit";

const Academic = (props) => {

    const auth = useSelector((state) => state.auth);

    const videoRef = useRef(null);
    const [loading, setLoading] = useState(true);
    const [course, setCourse] = useState(null);
    const [video, setVideo] = useState({
        url: null,
        title: null
    });

    const id = props.match.params.id;

    useEffect(() => {
        videoRef.current?.load();
    }, [video.url]);

    useEffect(() => {
        getAcademic(id).then((response) => {
            if(!response.error) {
                setCourse(response.course);
                setLoading(false);
            }
        })
    }, []);

    const playVideo = (video) => {
        setVideo({
            url: video.uri,
            title: video.name
        });

        $("#video").html(`<source src=${video.uri} type="video/mp4"/>` );

        UIkit.modal("#video-modal", {bgclose: false, keyboard:false}).show();
        return false;
    }

    $(function() {
        $('#video-modal').on({
            'hide.uk.modal': function () {
                videoRef.current.pause();
            }
        });
    })

    return (
        <div>
            {loading ? <div className="h-screen flex item-center justify-center">
                <div className="loader">
                    <BeatLoader color="#003074" size={20} />
                </div>
            </div> : <div>
                <div className="bg-gradient-to-tr from-blue-500 to-blue-700 text-white">
                    <div className="container p-0">
                        <div className="lg:flex items-center lg:space-x-12 lg:py-10 p-4">
                            <div className="lg:w-4/12">
                                <div className="w-full h-44 overflow-hidden rounded-lg relative lg:mb-0 mb-4">
                                    <img src={course.cover.uri} alt="" className="w-full h-full absolute inset-0 object-cover"/>
                                    <Link to="#" className="uk-position-center" onClick={playVideo.bind(null, course.contents[0].videos[0])} uk-toggle>
                                        <img src="assets/images/icon-play.svg" className="w-16 h-16" alt=""/>
                                    </Link>
                                </div>
                            </div>
                            <div className="lg:w-8/12">

                                <h1 className="lg:leading-10 lg:text-2xl text-white text-xl leading-8 font-semibold">{course.name}</h1>
                                <p className="line-clamp-2 mt-3 md:block hidden"> {truncate(course.description, 150)} </p>
                                <ul className="flex text-gray-100 gap-4 mt-4 mb-1">
                                    <li className="flex items-center">
                                        <span className="avg bg-yellow-500 mr-2 px-2 rounded text-white font-semiold"> 4.9 </span>
                                        <div className="star-rating text-yellow-200">
                                            <ion-icon name="star"></ion-icon>
                                            <ion-icon name="star"></ion-icon>
                                            <ion-icon name="star"></ion-icon>
                                            <ion-icon name="star"></ion-icon>
                                            <ion-icon name="star-half"></ion-icon>
                                        </div>
                                    </li>
                                </ul>
                                <ul className="flex text-gray-100 gap-4 mt-4 mb-1">
                                    <div className="flex space-x-2 items-center text-sm pt-2">
                                        <div> {course.category.name}</div>
                                        <div>·</div>
                                        <div> {course.contents.length} Lessons</div>
                                    </div>
                                </ul>

                            </div>

                        </div>
                    </div>
                </div>


                <div className="bg-white border-b z-20 mb-4 overflow-hidden" uk-sticky="media: 992; offset:70">
                    <div className="mcontainer py-0 lg:px-20 flex justify-between items-center">

                        <nav className="cd-secondary-nav nav-smal l flex-1">
                            <ul className="space-x-3" uk-scrollspy-nav="closest: li; scroll: true">
                                <li><a href="#Overview" uk-scroll>Overview</a></li>
                                <li><a href="#curriculum" uk-scroll>Curriculum</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>

                <div className="container p-0">

                    <div className="space-y-5 lg:w-9/12 mx-auto">

                        <div className="tube-card p-5 lg:p-8" id="Overview">
                            <div className="space-y-6">
                                <div>
                                    <h3 className="text-lg font-semibold mb-3"> Description </h3>
                                    <p>
                                        {course.description}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div id="curriculum">
                            <h3 className="mb-4 text-xl font-semibold lg:mb-5"> Course Curriculum </h3>
                            <ul uk-accordion="multiple: true" className="tube-card p-4 divide-y space-y-3">
                                {course.contents.map((item, index) => {
                                    return(
                                        <li key={item._id} className={index <= 0 ? 'uk-open' : 'pt-2'}>
                                            <a className="uk-accordion-title text-md mx-2 font-semibold" href="#">
                                                <div className="mb-1 text-sm font-medium"> Lesson {item.position}</div>{item.name} </a>
                                            <div className="uk-accordion-content mt-3 text-base">
                                                <ul className="course-curriculum-list font-medium">
                                                    {item.videos.map((video) => {
                                                        return(video.uri !== null ? <li key={index} className=" hover:bg-gray-100 p-2 flex rounded-md">
                                                                <Link className="d-flex" to="#" onClick={playVideo.bind(null, video)} uk-toggle>
                                                                    <span className="text-sm ml-auto"> <ion-icon name="play-circle" class="text-2xl mr-2"></ion-icon> </span>
                                                                    {video.name}
                                                                </Link>
                                                        </li> : <li key={index} className=" hover:bg-gray-100 p-2 flex rounded-md">
                                                                <div className="d-flex">
                                                                    <span className="text-sm ml-auto"> <ion-icon name="play-circle" class="text-2xl mr-2"></ion-icon> </span>
                                                                    {video.name}
                                                                </div>
                                                                <span className="text-sm ml-auto"> <ion-icon name="lock-closed"></ion-icon> </span>
                                                        </li>
                                                        )
                                                    })}
                                                    {item.document !== null ? item.document.uri !== null ?
                                                        <li key={index} className=" hover:bg-gray-100 p-2 flex rounded-md">
                                                            <a className="d-flex" href={item.document.uri} target="_blank">
                                                                <span className="text-sm ml-auto"> <ion-icon name="document" class="text-2xl mr-2"></ion-icon> </span>
                                                                {item.document.name}
                                                            </a>
                                                        </li> : <li key={index} className=" hover:bg-gray-100 p-2 flex rounded-md">
                                                                <div className="d-flex">
                                                                    <span className="text-sm ml-auto"> <ion-icon name="document" class="text-2xl mr-2"></ion-icon> </span>
                                                                    {item.document.name}
                                                                </div>
                                                                <span className="text-sm ml-auto"> <ion-icon name="lock-closed"></ion-icon> </span>
                                                            </li>
                                                        : null}
                                                    {item.simulator !== null ? item.simulator !== null ?
                                                            <li key={index} className=" hover:bg-gray-100 p-2 flex rounded-md">
                                                                <a className="d-flex" href={`#/interactive/${encodeQueryParameter(Object.assign(item.simulator, {title: item.name}))}`} target="_blank">
                                                                    <span className="text-sm ml-auto"> <ion-icon name="film" class="text-2xl mr-2"></ion-icon> </span>
                                                                    <p>Interactive Learning</p>
                                                                </a>
                                                            </li> : <li key={index} className=" hover:bg-gray-100 p-2 flex rounded-md">
                                                                <div className="d-flex">
                                                                    <span className="text-sm ml-auto"> <ion-icon name="film" class="text-2xl mr-2"></ion-icon> </span>
                                                                    Interactive Learning
                                                                </div>
                                                                <span className="text-sm ml-auto"> <ion-icon name="lock-closed"></ion-icon> </span>
                                                            </li>
                                                        : null}
                                                </ul>
                                            </div>
                                        </li>
                                    )
                                    })}
                            </ul>
                        </div>
                    </div>

                </div>

                <Footer/>

                <div id="video-modal">
                    <div className="uk-modal-dialog shadow-lg rounded-md">
                        <button className="uk-modal-close-default" type="button" uk-close>
                            <ion-icon name="close-circle" className="text-2xl" size="large"></ion-icon>
                        </button>
                        <div className="uk-modal-header  rounded-t-md">
                            <h4 className="text-lg font-semibold mb-2"> Trailer video </h4>
                        </div>

                        <div className="embed-video">
                            <video ref={videoRef} id="video" controlsList="nodownload" oncontextmenu="return false;" controls autoPlay/>
                        </div>

                        <div className="uk-modal-body">
                            <h3 className="text-lg font-semibold mb-2">{video.title}</h3>
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    );
};

export default Academic;
