import React, {useState} from 'react';
import {askQuestion} from "../../api/app";
import Footer from "../../components/layout/Footer";
import $ from "jquery";
import {BeatLoader} from "react-spinners";
import {capitalize} from "../../utils/capitalize";

const Question = () => {

    const [loading, setLoading] = useState(false);
    const [questions, setQuestions] = useState([]);

    const onAskQuestion = () => {
        setLoading(true);
        askQuestion($("#question").val()).then((response) => {
            if(!response.error) {
                $("#question").val(null);
                setQuestions(prevArray => [...prevArray, {
                    text: response.payload.question,
                    answer: response.payload.answer
                }]);
                setLoading(false);
            }
        });
    }

    return (
        <div>
            <div className="md:pt-16 md:pb-7 py-6 bg-blue-600 lg:-mt-25">

                <div className="container m-auto px-4">

                    <div className="flex flex-col justify-center items-center md:p-7 text-white">

                        <div className="lg:text-4xl text-2xl font-semibold mb-6"> Ask ZStudy?</div>

                        <div className="bg-white mb-3 relative rounded-md lg:w-2/3 w-full shadow-md" id="search">
                            {loading ? <div className="absolute h-full hydrated right-3 mt-2 text-lg md text-gray-600">
                                <BeatLoader color="#003074" size={10} />
                            </div> : <ion-icon onClick={onAskQuestion.bind(null)} name="send" class="absolute h-full hydrated right-3 text-lg md text-gray-600" role="img" aria-label="search outline" style={{cursor: "pointer"}}></ion-icon>}
                            <input placeholder="Search" className="focus:outline-none h-12 pl-5 rounded-lg w-full" id="question"/>
                        </div>

                        <p className="text-sm text-center">
                            Example: Who's the first president of Zambia?
                        </p>

                    </div>

                </div>

            </div>

            <div className="py-12">
                <div className="container lg:w-10/12">
                    {questions.reverse().map((item) => {
                        return(
                            <div className="mb-5">
                                <ul className="uk-accordion space-y-2" uk-accordion="">
                                    <li className="bg-white px-6 py-4 rounded shadow hover:shadow-md uk-open">
                                        <a className="uk-accordion-title text-base" href="#"> <strong>{capitalize(item.text)}</strong> </a>
                                        <div className="uk-accordion-content mt-3">
                                            <p> {item.answer} </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        )
                    })}
                </div>
            </div>

            <Footer/>
        </div>
    );
};

export default Question;
